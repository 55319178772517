const sectors = [
  {
    id: 1,
    label: "Recebimento",
    apiReference: "RECEBIMENTO",
    rows: [
      {
        row: "Fiscal",
        rowStatus: "yellow",
        apiReference: "FISCAL",
        hideItems: true,
        sumRow: true,
        timeStatus: "red",
      },
      {
        row: "A Fazer",
        rowStatus: "blue",
        apiReference: "A FAZER",
        sumRow: true,
        timeStatus: "green",
      },
      {
        row: "Fazendo",
        rowStatus: "green",
        apiReference: "FAZENDO",
        sumRow: true,
        timeStatus: "yellow",
      },
      {
        row: "Com Ocorrência",
        rowStatus: "red",
        apiReference: "COM OCORRENCIA",
        hideRequests: true,
        timeStatus: "red",
        dateFormat: "days",
      },
    ],
  },
  {
    id: 2,
    label: "Embalagem",
    apiReference: "EMBALAGEM",
    rowGap: 90,
    rows: [
      {
        row: "Em Recebimento",
        rowStatus: "yellow",
        apiReference: "EM RECEBIMENTO",
        sumRow: true,
        timeStatus: "red",
      },
      {
        row: "A Fazer",
        rowStatus: "blue",
        apiReference: "A FAZER",
        hideRequests: true,
        sumRow: true,
        timeStatus: "green",
      },
    ],
  },
  {
    id: 3,
    label: "Trânsito",
    apiReference: "TRANSITO",
    rowGap: 55,
    rows: [
      {
        row: "No Recebimento",
        rowStatus: "green",
        apiReference: "EM RECEBIMENTO",
        sumRow: true,
        timeStatus: "yellow",
      },
      {
        row: "Na Embalagem",
        rowStatus: "yellow",
        apiReference: "EM EMBALAGEM",
        sumRow: true,
        hideRequests: true,
        timeStatus: "red",
      },
      {
        row: "A Fazer",
        rowStatus: "blue",
        apiReference: "A FAZER",
        sumRow: true,
        hideRequests: true,
        timeStatus: "green",
      },
    ],
  },
  {
    id: 4,
    label: "Separação",
    apiReference: "SEPARACAO",
    rows: [
      {
        row: "Em Processamento",
        rowStatus: "yellow",
        apiReference: "EM PROCESSAMENTO",
        sumRow: true,
        timeStatus: "red",
      },
      {
        row: "A Fazer",
        rowStatus: "blue",
        apiReference: "A FAZER",
        sumRow: true,
        timeStatus: "green",
      },
      {
        row: "Fazendo",
        rowStatus: "green",
        apiReference: "FAZENDO",
        sumRow: true,
        timeStatus: "yellow",
      },
      {
        row: "Com Ocorrência",
        rowStatus: "red",
        apiReference: "OCORRENCIA",
        timeStatus: "red",
        dateFormat: "days",
      },
    ],
  },
  {
    id: 5,
    label: "Conferência",
    apiReference: "CONFERENCIA",
    rows: [
      {
        row: "Em Separação",
        rowStatus: "yellow",
        sumRow: true,
        apiReference: "SEPARACAO",
        timeStatus: "red",
      },
      {
        row: "A Fazer",
        rowStatus: "blue",
        sumRow: true,
        apiReference: "A FAZER",
        timeStatus: "green",
      },
      {
        row: "Fazendo",
        rowStatus: "green",
        sumRow: true,
        apiReference: "FAZENDO",
        timeStatus: "yellow",
      },
      {
        row: "Com Ocorrência",
        rowStatus: "red",
        apiReference: "OCORRENCIA",
        timeStatus: "red",
        dateFormat: "days",
      },
    ],
  },
  {
    id: 6,
    label: "Expedição",
    apiReference: "EXPEDICAO",
    rowGap: 90,
    rows: [
      {
        row: "Em Conferência",
        rowStatus: "yellow",
        apiReference: "EM CONFERENCIA",
        sumRow: true,
        timeStatus: "red",
      },
      {
        row: "A Fazer",
        rowStatus: "blue",
        apiReference: "A FAZER",
        sumRow: true,
        timeStatus: "green",
      },
    ],
  },
];

export default sectors;
