import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Container, StyledTitle, StyledGrid, Loader } from "./styles";

import sectors from "../../utils/sectors";
import warehouses from "../../utils/warehouses";
import getDataWithSectorAndWarehouse from "../../api";

function SectorTable() {
  const { state } = useLocation();
  const [sectorIndex, setSectorIndex] = useState(null);
  const [warehouseIndex, setWarehouseIndex] = useState(null);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentSector = sectors.find(({ id }) => id === sectorIndex) || {};
  const currentWarehouse =
    warehouses.find(({ id }) => id === warehouseIndex) || {};

  useEffect(() => {
    if (state) {
      setSectorIndex(state.sectorIndex);
      setWarehouseIndex(state.warehouseIndex);
    }
  }, [state]);

  useEffect(() => {
    let dataHash;

    function injectRowsData(rows, rowsData) {
      return rows.map((row) => {
        const rowData = rowsData.find(
          (data) => data.nome === row.apiReference.toUpperCase()
        );

        return {
          ...row,
          amountOfRequests: rowData?.pedido,
          amountOfItems: rowData?.item,
          timeSpent: rowData?.tempo,
        };
      });
    }

    function createTotalOfRows(rows) {
      function shouldSum(prevAmount, currAmount, includeCurr) {
        if (includeCurr) return prevAmount + currAmount;

        return prevAmount;
      }

      return rows.reduce((prevRow, currRow) => {
        const amountOfRequests = shouldSum(
          prevRow.amountOfRequests || 0,
          currRow.amountOfRequests,
          !currRow.hideRequests
        );
        const amountOfItems = shouldSum(
          prevRow.amountOfItems || 0,
          currRow.amountOfItems,
          !currRow.hideItems
        );

        const prevTimeSpent = (prevRow.timeSpent || ":").split(":");
        const currTimeSpent = (currRow.timeSpent || ":").split(":");

        const hours = Number(prevTimeSpent[0]) + Number(currTimeSpent[0]);
        const minutes = Number(prevTimeSpent[1]) + Number(currTimeSpent[1]);

        const timeSpent = `${hours + Math.floor(minutes / 60)}:${minutes % 60}`;

        return {
          amountOfRequests,
          amountOfItems,
          timeSpent,
        };
      }, {});
    }

    function formatRowsTime(rows) {
      function formatDays(timeSpent) {
        const formattedTimeSpent = `${timeSpent} dias`;

        return formattedTimeSpent;
      }

      function formatTime(timeSpent) {
        const arrayTimeSpent = (timeSpent || ":").split(":");
        const formattedTimeSpent = `${arrayTimeSpent[0]}h${arrayTimeSpent[1]}m`;

        return formattedTimeSpent;
      }

      return rows.map(({ dateFormat, ...row }) => {
        let timeSpent;

        if (dateFormat === "days") {
          timeSpent = formatDays(row.timeSpent);
        } else {
          timeSpent = formatTime(row.timeSpent);
        }

        return {
          ...row,
          timeSpent,
        };
      });
    }

    function settleRowsWithData(rows, isDataEmpty) {
      const defaultTotalRow = {
        row: "Total",
        rowStatus: "",
        timeStatus: "",
      };

      const beforeTotalRows = rows.filter(({ sumRow }) => sumRow);
      const afterTotalRows = rows.filter(({ sumRow }) => !sumRow);

      if (!isDataEmpty) {
        const totalRowData = createTotalOfRows(beforeTotalRows);

        const formattedRows = formatRowsTime([
          ...beforeTotalRows,
          {
            ...defaultTotalRow,
            ...totalRowData,
          },
          ...afterTotalRows,
        ]);

        setRows(formattedRows);
      } else {
        setRows([...beforeTotalRows, ...defaultTotalRow, ...afterTotalRows]);
      }
    }

    function compareDataHash(newDataHash) {
      if (newDataHash === dataHash) return true;
      return false;
    }

    function settleRows(response) {
      const rows = injectRowsData(currentSector.rows, response);
      settleRowsWithData(rows, response.length <= 0);
    }

    async function request() {
      try {
        const response = await getDataWithSectorAndWarehouse({
          sector: currentSector.apiReference.toLowerCase(),
          warehouse: currentWarehouse.apiReference.toLowerCase(),
        });

        const newDataHash = JSON.stringify(response);
        const areDataHashEqual = compareDataHash(newDataHash);

        if (!areDataHashEqual) {
          settleRows(response);
          dataHash = newDataHash;
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function loadRequest() {
      setIsLoading(true);

      await request();

      setIsLoading(false);
    }

    if (sectorIndex && warehouseIndex) {
      loadRequest();

      const intervalFunc = setInterval(async () => {
        await request();
      }, 60000);

      return () => clearInterval(intervalFunc);
    }
  }, [sectorIndex, warehouseIndex]);

  return (
    <Container className="text--large" rowGap={currentSector.rowGap}>
      <StyledTitle>
        <span>{currentSector.label}</span>
      </StyledTitle>
      {!isLoading ? (
        rows.length > 0 && (
          <StyledGrid rowGap={currentSector.rowGap}>
            <div className="grid__header text--small">
              <span>Pedidos</span>
              <span>Itens</span>
              <span>Tempo</span>
            </div>
            <div className="grid__body">
              {(rows || []).map(
                (
                  {
                    row,
                    rowStatus,
                    amountOfRequests,
                    amountOfItems,
                    hideRequests,
                    hideItems,
                    timeSpent,
                    timeStatus,
                  },
                  index
                ) => (
                  <React.Fragment key={`row-${index}`}>
                    <span className={`bg-color--${rowStatus}`}>{row}</span>
                    <span className={hideRequests ? "cell--hidden" : ""}>
                      {amountOfRequests}
                    </span>
                    <span className={hideItems ? "cell--hidden" : ""}>
                      {amountOfItems}
                    </span>
                    <span className={`bg-color--${timeStatus}`}>
                      {timeSpent}
                    </span>
                  </React.Fragment>
                )
              )}
            </div>
          </StyledGrid>
        )
      ) : (
        <Loader />
      )}
    </Container>
  );
}

export default SectorTable;
