const warehouses = [
  {
    id: 1,
    label: "Campinas",
    apiReference: "campinas",
  },
  {
    id: 2,
    label: "Minas",
    apiReference: "minas",
  },
];

export default warehouses;
