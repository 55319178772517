import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr;

  span,
  label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  hr {
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 0.5vh white;
    margin-top: 0.5rem;
  }
`;

export const StyledTitle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;

  margin: 1.6rem;
  margin-bottom: 0;

  span {
    font-size: inherit;
  }
`;

export const StyledSelectors = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;

  margin: 3.5rem;
  margin-bottom: 0;

  overflow: hidden;

  & > div {
    overflow: overlay;

    ::-webkit-scrollbar {
      width: 1rem;
      height: 1rem;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      visibility: hidden;
      background: white;
      height: 40px;
      border: 4px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: white;
    }

    scrollbar-width: thin;
    scrollbar-color: white white;

    &:hover::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
`;

export const StyledSelector = styled.div`
  margin-bottom: 3.5rem;
  cursor: pointer;

  border: solid 0.5vh white;

  &:hover {
    background-color: white;
    color: black;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: white;
      color: black;
    `}

  label {
    font-size: inherit;
    padding: 1rem 0;
    cursor: pointer;
  }

  input {
    display: none;
  }
`;
