import client from "./client";

const getDataWithSectorAndWarehouse = async ({ sector, warehouse }) =>
  client
    .get(`${sector}/${warehouse}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response || err;
    });

export default getDataWithSectorAndWarehouse;
