import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: grid;
  grid-template-rows: 0.21fr 1fr;
  gap: ${({ rowGap = 35 }) => `${rowGap / 10}rem`};

  padding: 1.6rem 3.5rem 3.5rem 3.5rem;
  padding-bottom: ${({ rowGap = 35 }) => `${rowGap / 10}rem`};

  hr {
    width: 100%;
    padding: 0;
    border: 0;
    border: solid 0.5vh white;
    margin-top: 0.5rem;
  }

  overflow: hidden;

  p,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  *.bg-color--red {
    background-color: red;
  }

  *.bg-color--yellow {
    background-color: yellow;
    color: black;
  }

  *.bg-color--blue {
    background-color: blue;
  }

  *.bg-color--green {
    background-color: green;
  }
`;

export const StyledTitle = styled.div`
  height: 100%;
  position: relative;

  & > span {
    font-size: inherit;

    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    margin-right: auto;
    margin-left: auto;
  }
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;

  span {
    font-size: inherit;
    font-weight: 500;
    border: solid 0.5vh white;
  }

  ${({ rowGap = 35 }) => css`
    .grid__header {
      display: grid;
      grid-template-columns: 2fr repeat(3, 1fr);

      column-gap: 2rem;
      row-gap: ${rowGap / 10}rem;

      span:first-of-type {
        grid-column: 2;
      }

      span {
        font-weight: 400;
        padding: 0.25rem 0;
      }
    }

    .grid__body {
      display: grid;
      grid-template-columns: 2fr repeat(3, 1fr);

      column-gap: 2rem;
      row-gap: ${rowGap / 10}rem;

      .cell--hidden {
        visibility: hidden;
      }

      span:nth-child(-n + 4) {
        border-top: 0;
      }

      span:nth-child(1) {
        border-top: solid 0.5vh white;
      }
    }
  `}
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  border: 0.8rem solid transparent;
  border-top: 0.8rem solid white;
  border-left: 0.8rem solid white;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
