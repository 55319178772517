import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import warehouses from "../../utils/warehouses";
import sectors from "../../utils/sectors";

import {
  Container,
  StyledTitle,
  StyledSelectors,
  StyledSelector,
} from "./styles";

function SelectConfig() {
  const navigate = useNavigate();

  const [warehouseIndex, setWarehouseIndex] = useState(null);
  const [sectorIndex, setSectorIndex] = useState(null);

  useEffect(() => {
    if (Number.isInteger(warehouseIndex) && Number.isInteger(sectorIndex)) {
      navigate("/setor", { state: { warehouseIndex, sectorIndex } });
    }
  }, [warehouseIndex, sectorIndex]);

  function handleChangeWarehouse(e) {
    setWarehouseIndex(Number(e.target.value));
  }

  function handleChangeSector(e) {
    setSectorIndex(Number(e.target.value));
  }

  return (
    <Container>
      <StyledTitle className="text--large">
        <span>ARMAZÉNS:</span>
        <span>SETORES:</span>
      </StyledTitle>
      <hr />
      <StyledSelectors className="text--medium">
        <div onChange={handleChangeWarehouse}>
          {warehouses.map((warehouse) => (
            <StyledSelector
              key={`field-warehouse-${warehouse.id}`}
              isSelected={warehouse.id === warehouseIndex}
            >
              <input
                type="radio"
                id={warehouse.label}
                name="field-warehouse"
                value={warehouse.id}
              />
              <label htmlFor={warehouse.label}>{warehouse.label}</label>
            </StyledSelector>
          ))}
        </div>
        <div onChange={handleChangeSector}>
          {sectors.map((sector) => (
            <StyledSelector
              key={`field-sector-${sector.id}`}
              isSelected={sector.id === sectorIndex}
            >
              <input
                type="radio"
                id={sector.label}
                name="field-sector"
                value={sector.id}
              />
              <label htmlFor={sector.label}>{sector.label}</label>
            </StyledSelector>
          ))}
        </div>
      </StyledSelectors>
    </Container>
  );
}

export default SelectConfig;
