import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SelectConfig from "./pages/SelectConfig";
import SectorTable from "./pages/SectorTable";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SelectConfig />} />
        <Route path="/setor" element={<SectorTable />} />
      </Routes>
    </Router>
  );
}

export default App;
